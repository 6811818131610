import React from "react"
import Card from "../components/card"

import Layout from "../components/layout"

import "../components/lyrics.css"

const EaterLyrics = () => (
  <Layout>
    <div style={{ marginTop: "24px", padding: "0 1em" }}>
      <div className="fill-height-or-more">
        <div className="lyrics">
          <h1>I AM</h1>
          <b>Verse 1</b>
          <p>
            Immanuel <br />
            So many names that i have heard
            <br />
            But the father, is my father this I’ve learned
          </p>
          <p>
            God of jacob <br />
            Before you speak know he’s been there
            <br /> Know he’ll never leave your side <br />
            The doors been open since you left
          </p>
          <b>Chorus</b>
          <p>
            But what if I can’t speak What if I can’t hear The words that you
            give
          </p>
          <p>
            What if I can’t stand What if i can’t lead By whom am I sent Well he
            says I am
          </p>
          <p>
            Eternal Father <br />
            In the days that we begun
            <br /> It is the same breath of life
            <br /> You speak your promises on us
          </p>
          <b>Bridge</b>
          <p>
            I AM <br />
            This is his name forever
            <br /> I AM <br />
            All that we must remember I AM
            <br />
            End of the conversation
            <br /> I AM <br />
            For every generation
          </p>
          <p>
            Ain’t no Father like the Father that made me
            <br /> Ain’t no Father like the Father that raised me
            <br /> Ain’t no Father like the Father that saved me
          </p>
          <p>
            Ain’t no Father like the Father that made me
            <br /> Ain’t no Father like the Father that saved me
            <br /> He gave me life, gave me eyes
            <br /> To see what’s mine, I’m gone survive
            <br /> He raised me right
          </p>
          <p>
            I AM <br />
            This is his name forever
            <br /> I AM <br />
            All that we must remember
            <br /> I AM End of the conversation
            <br /> I AM <br />
            For every generation
          </p>
          <p>
            Man of Sorrows No matter what I’ve done before You still raised me
            as your child The one you sent your son to die for
          </p>
          <h1>Nothing Else</h1>
          <b>Chorus</b>
          <p>
            I'm caught up in Your presence
            <br /> I just want to sit here at Your feet
            <br />
            I'm caught up in this holy moment
            <br /> I never want to leave
            <br /> Oh, I'm not here for blessings
            <br /> Jesus, You don't owe me anything
            <br /> More than anything that You can do <br />I just want You
          </p>

          <b>Verse 1</b>
          <p>
            I'm sorry when I've just gone through the motions
            <br /> I'm sorry when I just sang another song
            <br /> Take me back to where we started
            <br /> I open up my heart to You
          </p>
          <b>Verse 2</b>
          <p>
            I'm sorry when I've come with my agenda
            <br /> I'm sorry when I forgot that You're enough <br />
            Take me back to where we started
            <br /> I open up my heart to You
          </p>
          <b>Chorus</b>
          <p>
            I'm caught up in Your presence I just want to sit here at Your feet
            I'm caught up in this holy moment I never want to leave Oh, I'm not
            here for blessings Jesus, You don't owe me anything And more than
            anything that You can do I just want You
            <b>Bridge</b>I just want You Nothing else, nothing else Nothing else
            will do I just want You Nothing else, nothing else Nothing else will
            do I just want You Nothing else, nothing else Nothing else will do I
            just want You Nothing else, nothing else, Jesus Nothing else will do
            I just want You Nothing else, nothing else Nothing else will do I
            just want You Nothing else, nothing else, Jesus Nothing else will do
          </p>
        </div>
        <Card title="Back To Home" to="/" bgColor="#FF5B5E" />
      </div>
    </div>
  </Layout>
)

export default EaterLyrics
